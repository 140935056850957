import { ref, computed, onMounted, onUnmounted } from 'vue'
import { Modal, message } from 'ant-design-vue'
import { useStore } from '@/store'
import { getProductList, passProduct, publishProduct, rejectProduct, cancelSaleArea } from '@/api/goodsJudge'
import { handleExportFile, OK, reportEvent } from '@/common'
import { exportGoodsCsv } from '@/api/goodsSetting'
import type { Key } from 'ant-design-vue/es/table/interface'
import type { ColumnType } from 'ant-design-vue/es/table'

interface EmitsType {
  (e: 'goDetail', id: string | number): void
  (e: 'update:tableLoading', value: boolean): void
  (e: 'updateStatistics'): void
}

interface filterObj {
  keyword?: string | number
  publishStatuses?: number[]
  prodChnl?: string
  [key: string]: any
}

// 表格列定义
export const columns: ColumnType[] = [
  { title: '序号', dataIndex: 'index', key: 'index', width: 60 },
  { title: '商品类型', dataIndex: 'prodType', key: 'prodType', width: 120 },
  { title: '内购ID', dataIndex: 'prodId', key: 'prodId', width: 300 },
  { title: '商品名称', dataIndex: 'name', key: 'name', width: 120 },
  { title: '商品描述', dataIndex: 'prodDescription', key: 'prodDescription', width: 200 },
  { title: '默认价格', dataIndex: 'price', key: 'price' },
  { title: '审核状态', dataIndex: 'approvalStatus', key: 'approvalStatus', width: 120 },
  { title: '同步状态', dataIndex: 'publishStatus', key: 'publishStatus', width: 120 },
  { title: '商品状态', dataIndex: 'prodStatus', key: 'prodStatus', width: 120 },
  { title: '审核类型', dataIndex: 'optType', key: 'optType' },
  { title: '提交人', dataIndex: 'submitName', key: 'submitName' },
  { title: '最近提审时间', dataIndex: 'submitTime', key: 'submitTime' },
  { title: '最近同步时间', dataIndex: 'publishTime', key: 'publishTime' },
  { title: '操作', dataIndex: 'handle', key: 'handle', align: 'right', fixed: 'right' },
]

// 状态列表
export const publishStatusList: { [key: number]: string } = { 0: '--', 10: '成功', 20: '失败' }
export const approvalStatusList: { [key: number]: string } = { 0: '待审核', 10: '审核中', 20: '已驳回', 30: '已通过' }
export const prodStatusList: { [key: number]: string } = { 0: '--', 10: '有效', 20: '失效' }
export const statusColorList: { [key: number]: string } = { 0: 'orange', 10: 'blue', 20: 'red', 30: 'green' }
export const optTypeList: { [key: number]: string } = { 0: '新增', 10: '修改', 20: '停用', 30: '启用' }
export const prodTypeList: { [key: number]: string } = {
  0: '未知',
  10: '非消耗型',
  20: '消耗型',
  31: '非自动续期订阅',
  32: '自动续期订阅',
}

export const useJudgeTable = (emits: EmitsType) => {
  const userInfo = computed(() => useStore().userInfo && JSON.parse(useStore().userInfo))
  const dataSource = ref<any[]>([])
  const selectedRowKeys = ref<Key[]>([])
  const pageIndex = ref<number>(1)
  const pageSize = ref<number>(20)
  const pageTotal = ref<number>(0)
  const searchObj = ref<filterObj>({})
  const tableRef = ref<any>()
  const tableHeight = ref<number>(500)
  const editItem = ref<any>({})
  const loading = ref(false)
  const passLoading = ref(false)
  const rejectVisible = ref(false)
  const isEditModal = ref(true)
  const appName = ref('')
  const exportLoading = ref(false)

  const getData = async () => {
    const startTime = new Date().getTime()
    let params: any = {
      pageNum: 1,
      pageSize: 100000,
      approvalStatuses: [10, 20, 30],
      fuzzy: searchObj.value?.productName,
      orderBy: 'submit_time', // 支持的排序字段,字段后端服务要校验，审核信息页传：publish_time,商品审核页传：submit_time
      orderDirection: 'desc', // 排序方式
    }
    Object.keys(searchObj.value).forEach((item) => {
      if (searchObj.value[item] || searchObj.value[item] === 0) {
        params = {
          ...params,
          [item]: searchObj.value[item],
        }
      }
    })
    dataSource.value = []
    emits('update:tableLoading', true)

    try {
      const { data, code } = await getProductList(params)
      if (code === OK) {
        if (data) {
          pageTotal.value = data.total
          dataSource.value = data.list.map((item: any) => {
            return {
              ...item,
              changePriceList: item.productNativePrice?.filter(
                (priceInfo: any) => priceInfo.originProdPrice !== priceInfo.prodPrice,
              ),
            }
          })
        } else {
          pageTotal.value = 0
        }
        reportEvent({
          eventName: '_search',
          properties: {
            ui_name: '商品审核搜索',
            app_name: '',
            return_duration: (new Date().getTime() - startTime) / 1000,
            query_status: 'succeed',
          },
        })
      } else {
        reportEvent({
          eventName: '_search',
          properties: {
            ui_name: '商品审核搜索',
            app_name: '',
            return_duration: (new Date().getTime() - startTime) / 1000,
            query_status: 'failed',
          },
        })
      }
    } catch (e) {
      reportEvent({
        eventName: '_search',
        properties: {
          ui_name: '商品审核搜索',
          app_name: '',
          return_duration: (new Date().getTime() - startTime) / 1000,
          query_status: 'failed',
        },
      })
      console.log('%c TableBlock.vue-145', 'color:#fff;background:#e4393c;padding:2px', e)
    }
    emits('update:tableLoading', false)
  }

  const searchData = async (obj: filterObj) => {
    selectedRowKeys.value = []
    pageIndex.value = 1
    searchObj.value = obj
    await getData()
  }

  const openDetail = async (item: any) => {
    editItem.value = item
    emits('goDetail', item.id)
  }

  const batchPass = async () => {
    if (!selectedRowKeys.value.length) return message.error('请选择商品')
    const productList = selectedRowKeys.value?.map((item: any) => {
      return {
        id: item,
      }
    })
    passLoading.value = true
    try {
      const { code, msg } = await passProduct({
        productList,
        prodChnl: searchObj.value.prodChnl || '',
      })
      if (code === OK) {
        Modal.confirm({
          title: '商品已通过审核！',
          centered: true,
          content: '商品已通过审核，是否立即同步到第三方后台？',
          okText: '立即同步',
          cancelText: '稍后',
          onOk: () => {
            batchPublishProduct()
            passLoading.value = false
          },
          onCancel: async () => {
            selectedRowKeys.value = []
            message.success(msg)
            pageIndex.value = 1
            await getData()
            emits('updateStatistics')
            passLoading.value = false
          },
        })
      } else {
        passLoading.value = false
      }
    } catch (e) {
      passLoading.value = false
      console.log('%c[JudgeTableBlock.vue] - 188', 'padding:2px;border-radius:4px;background:lightcoral;color:#fff', e)
    }
  }

  const batchReject = async () => {
    isEditModal.value = false
    rejectVisible.value = true
  }

  const onSelectChange = (keys: Key[]) => {
    selectedRowKeys.value = keys
  }

  const batchPublishProduct = async () => {
    if (!selectedRowKeys.value.length) return message.error('请选择商品')
    const productList = selectedRowKeys.value?.map((item: any) => {
      return {
        id: item,
      }
    })
    loading.value = true
    try {
      const { code, msg } = await publishProduct({
        productList,
        prodChnl: searchObj.value.prodChnl || '',
      })
      if (code === OK) {
        message.success(msg)
      }
      selectedRowKeys.value = []
      pageIndex.value = 1
      await getData()
      emits('updateStatistics')
      loading.value = false
      reportEvent({
        eventName: 'element_use',
        properties: {
          ui_name: '商品审核',
          element_name: '同步商品',
        },
      })
    } catch (e) {
      loading.value = false
      console.log('%c[JudgeTableBlock.vue] - 188', 'padding:2px;border-radius:4px;background:lightcoral;color:#fff', e)
    }
  }

  const openRejectModal = (item: any) => {
    isEditModal.value = false
    editItem.value = item
    selectedRowKeys.value = [item.id]
    rejectVisible.value = true
  }

  const productPass = async (record: any) => {
    try {
      const { code, msg } = await passProduct({
        productList: [{ id: record.id }],
        prodChnl: searchObj.value.prodChnl || '',
      })
      if (code === OK) {
        message.success(msg)
        pageIndex.value = 1
        await getData()
        emits('updateStatistics')
        reportEvent({
          eventName: 'element_use',
          properties: {
            ui_name: '商品审核',
            element_name: '通过',
          },
        })
      }
    } catch (e) {
      console.log('%c[JudgeTableBlock.vue] - 333', 'padding:2px;border-radius:4px;background:lightcoral;color:#fff', e)
    }
  }

  const submitReject = async (reason: string) => {
    try {
      const { code, msg } = await rejectProduct({
        productList: dataSource.value
          .filter(
            (item: any) =>
              selectedRowKeys.value.includes(item.id) && !(item.approvalStatus === 20 || !!item.prodStatus),
          )
          .map((item: any) => ({ id: item.id })),
        rejectMsg: reason,
        prodChnl: searchObj.value.prodChnl || '',
      })
      if (code === OK) {
        message.success(msg)
        pageIndex.value = 1
        selectedRowKeys.value = []
        await getData()
        emits('updateStatistics')
        rejectVisible.value = false
        reportEvent({
          eventName: 'element_use',
          properties: {
            ui_name: '商品审核',
            element_name: '驳回',
          },
        })
      }
    } catch (e) {
      console.log('%c[JudgeTableBlock.vue] - 333', 'padding:2px;border-radius:4px;background:lightcoral;color:#fff', e)
    }
  }

  const exportGoodsExcel = async () => {
    let params: any = {
      ids: selectedRowKeys.value,
    }
    Object.keys(searchObj.value).forEach((item) => {
      if (searchObj.value[item] || searchObj.value[item] === 0) {
        params = {
          ...params,
          [item]: searchObj.value[item],
        }
      }
    })
    delete params.publishStatuses
    if (!params.dbtId) return
    exportLoading.value = true
    handleExportFile(
      await exportGoodsCsv(params),
      `${appName.value}_商品表`,
      searchObj.value.prodChnl !== 'xiaomi' ? 'csv' : 'xlsx',
    )
    exportLoading.value = false
    reportEvent({
      eventName: 'element_use',
      properties: {
        ui_name: '商品审核',
        element_name: '导出CSV',
      },
    })
  }

  const setProductName = (name: string) => {
    appName.value = name
  }

  const isProdStatus = computed(
    () => searchObj.value.publishStatuses?.includes(0) && searchObj.value.publishStatuses?.includes(20),
  )

  const pendingReviewNum = computed(() => {
    return (
      dataSource.value.filter((item: any) => selectedRowKeys.value.includes(item.id) && item.approvalStatus === 10)
        ?.length || 0
    )
  })

  const toBeRejectNum = computed(() => {
    return (
      dataSource.value.filter(
        (item: any) => selectedRowKeys.value.includes(item.id) && !(item.approvalStatus === 20 || !!item.prodStatus),
      )?.length || 0
    )
  })

  const toBeReleasedNum = computed(() => {
    return (
      dataSource.value.filter(
        (item: any) =>
          selectedRowKeys.value.includes(item.id) && item.approvalStatus === 30 && item.publishStatus !== 10,
      )?.length || 0
    )
  })

  const calculateTableHeight = () => {
    const windowHeight = window.innerHeight
    const otherHeight = 54 + 40 + 64 + 32 + 42 + 56 + 20 + 160
    tableHeight.value = windowHeight - otherHeight
  }

  const deleteChinaSaleArea = () => {
    // 过滤掉已经包含 CHN 的数据
    const filteredIds = dataSource.value
      .filter((item) => {
        if (!selectedRowKeys.value.includes(item.id)) return false
        try {
          const bizInfo = item.bizInfo || '{}'
          const removedAreaCodes = bizInfo.removedAreaCodes || []
          return !removedAreaCodes.includes('CHN')
        } catch (e) {
          return true
        }
      })
      .map((item) => item.id)

    if (filteredIds.length === 0) {
      message.info('所选商品已删除中国销售区域，无需重复操作')
      return
    }

    Modal.confirm({
      title: '删除中国销售地区',
      content: '确认后所有已创建商品将删除销售地区：中国',
      centered: true,
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        try {
          const res = await cancelSaleArea({
            areaCodes: ['CHN'],
            ids: filteredIds,
            dbtId: searchObj.value?.dbtId,
            prodChnl: searchObj.value?.prodChnl || '',
          })
          if (res.code === OK) {
            message.success('删除中国销售区域成功')
            getData()
          } else {
            message.error(res.msg || '删除中国销售区域失败')
          }
        } catch (error) {
          console.error('删除中国销售区域失败:', error)
          message.error('删除中国销售区域失败')
        }
      },
    })
  }

  onMounted(() => {
    calculateTableHeight()
    window.addEventListener('resize', calculateTableHeight)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', calculateTableHeight)
  })

  return {
    userInfo,
    dataSource,
    selectedRowKeys,
    pageIndex,
    pageSize,
    pageTotal,
    tableRef,
    tableHeight,
    editItem,
    loading,
    passLoading,
    rejectVisible,
    isEditModal,
    appName,
    exportLoading,
    isProdStatus,
    pendingReviewNum,
    toBeRejectNum,
    toBeReleasedNum,
    searchObj,
    getData,
    searchData,
    openDetail,
    batchPass,
    batchReject,
    onSelectChange,
    batchPublishProduct,
    openRejectModal,
    productPass,
    submitReject,
    exportGoodsExcel,
    setProductName,
    deleteChinaSaleArea,
  }
}
